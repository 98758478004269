body {
  font-family: 'Poppins', sans-serif;
  color: #333;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

p, a, span, li {
  font-weight: 400;
}

.cta-button {
  font-weight: 600;
}


.hero {
  background-color: #ffffff;
  padding: 80px 20px;
  position: relative;
  overflow: hidden;
}

.hero-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
  color: #151B28;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: #464BD8;
  margin-bottom: 40px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}


.cta-button {
  padding: 15px 30px;
  background-color: #2aefc8;
  color: #fff;
  text-decoration: none;
  border-radius: 50px;
  font-weight: 700;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #27d3b3;
}

.hero-shape {
  position: absolute;
  top: -100px;
  right: -100px;
  width: 400px;
  height: 400px;
  background-color: #464bd8;
  border-radius: 50%;
  z-index: -1;
}

.features {
  background-color: #f8f8f8;
  padding: 80px 20px;
}

.feature {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
  max-width: 1200px;
  margin: 0 auto;
}

.feature.reverse {
  flex-direction: row-reverse;
}

.feature-image {
  width: 50%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-text {
  width: 45%;
}

.feature-text h2 {
  font-size: 2rem;
  color: #151b28;
  margin-bottom: 20px;
}

.feature-text p {
  font-size: 1.2rem;
  color: #555;
}


.cta {
  background-color: #ffffff;
  text-align: center;
  padding: 80px 20px;
}

.cta h2 {
  font-size: 2.5rem;
  color: #151b28;
  margin-bottom: 20px;
}

.cta p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 40px;
}

.cta-button {
  padding: 15px 30px;
  background-color: #2aefc8;
  color: #fff;
  text-decoration: none;
  border-radius: 50px;
  font-weight: 700;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #27d3b3;
}

.footer {
  background-color: #151b28;
  color: #ffffff;
  text-align: center;
  padding: 20px 0;
}

.footer a {
  color: #2aefc8;
  text-decoration: none;
  margin: 0 10px;
}

.footer a:hover {
  text-decoration: underline;
}
